<template>
  <div>
    <b-card>
      <b-card bg-variant="primary" text-variant="dark" style="margin-bottom: 0px; background-color: #3c9ca5!important;" no-body>
        <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
          <i class="fa fa-file-text-o"></i>
          <template v-if="hospital_data.id">
            <span v-if="hospital_data.headers.hospital_name.translation.tr">{{ hospital_data.headers.hospital_name.translation.tr }} & </span> Hastane Ayarları
            <strong v-if="d_wdm225_key" style="color: red;"> [ id: {{ d_wdm225_key.split('_')[1] }} ]</strong>
          </template>
          <template v-else>
            <span style="color: red;">Hastane verisi kayıtlı değil.</span>
          </template>
          <b-button v-if="!all_hospital" class="pull-right" variant="primary" @click="save()"><i class="fa fa-save"></i> hastane bilgisini kaydet</b-button>
        </b-card-header>
        <b-row>
          <b-col sm="12" md="6" lg="3">
            <b-card no-body>
              <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1"><i class="fa fa-gears"></i> Ayarlar </b-card-header>
              <b-nav vertical>
                <b-nav-item @click="selected_nav_tab = 'hospital_code'" :active="selected_nav_tab === 'hospital_code'">
                  <div :style="selected_nav_tab === 'hospital_code' ? 'color: green;' : ''">
                    <i class="fa fa-handshake-o"></i> Hastane Kodu
                  </div>
                </b-nav-item>
                <b-nav-item @click="selected_nav_tab = 'captions'" :active="selected_nav_tab === 'captions'">
                  <div :style="selected_nav_tab === 'captions' ? 'color: green;' : ''">
                    <i class="fa fa-pencil"></i> Başlıklar
                  </div>
                </b-nav-item>
                <b-nav-item @click="selected_nav_tab = 'address'" :active="selected_nav_tab === 'address'">
                  <div :style="selected_nav_tab === 'address' ? 'color: green;' : ''">
                    <i class="fa fa-address-book"></i> Adres
                  </div>
                </b-nav-item>
                <b-nav-item @click="selected_nav_tab = 'center_phone_number'" :active="selected_nav_tab === 'center_phone_number'">
                  <div :style="selected_nav_tab === 'center_phone_number' ? 'color: green;' : ''">
                    <i class="fa fa-phone"></i> Telefon
                  </div>
                </b-nav-item>
                <b-nav-item @click="selected_nav_tab = 'logos'" :active="selected_nav_tab === 'logos'">
                  <div :style="selected_nav_tab === 'logos' ? 'color: green;' : ''">
                    <i class="fa fa-image"></i> Logolar
                  </div>
                </b-nav-item>
                <b-nav-item @click="selected_nav_tab = 'wisdomera_support'" :active="selected_nav_tab === 'wisdomera_support'">
                  <div :style="selected_nav_tab === 'wisdomera_support' ? 'color: green;' : ''">
                    <i class="fa fa-image"></i> WisdomEra Sorumluları
                  </div>
                </b-nav-item>
                <!-- hospital_data.id kontrolünün amacı hastane sisteme kaydedildimi kontrolünü yapmak. Henüz kaydedilmediyse aktif olmayacak -->
                <b-nav-item v-if="hospital_data.id" @click="selected_nav_tab = 'WisdomDataModalList'" :active="selected_nav_tab === 'WisdomDataModalList'">
                  <div :style="selected_nav_tab === 'WisdomDataModalList' ? 'color: green;' : ''">
                    <i class="fa fa-list"></i> WDM - Veri Modülleri Listesi
                  </div>
                </b-nav-item>
                <b-nav-item v-if="hospital_data.id" @click="selected_nav_tab = 'WisdomDataModal'" :active="selected_nav_tab === 'WisdomDataModal'">
                  <div :style="selected_nav_tab === 'WisdomDataModal' ? 'color: green;' : ''">
                    <i class="fa fa-edit"></i> WDM - Veri Model Detayları
                  </div>
                </b-nav-item>
                <b-nav-item v-if="hospital_data.id" @click="selected_nav_tab = 'WisdomTreeList'" :active="selected_nav_tab === 'WisdomTreeList'">
                  <div :style="selected_nav_tab === 'WisdomTreeList' ? 'color: green;' : ''">
                    <i class="fa fa-edit"></i> WTL - Veri Ağacı Detayları
                  </div>
                </b-nav-item>
                <b-nav-item v-if="hospital_data.id" @click="selected_nav_tab = 'UnitDefinitions'" :active="selected_nav_tab === 'UnitDefinitions'">
                  <div :style="selected_nav_tab === 'UnitDefinitions' ? 'color: green;' : ''">
                    <i class="fa fa-edit"></i> HBYS Unit Bilgileri
                  </div>
                </b-nav-item>
                <b-nav-item v-if="hospital_data.id" @click="selected_nav_tab = 'TransferPatientDataPersonnelIdList'" :active="selected_nav_tab === 'TransferPatientDataPersonnelIdList'">
                  <div :style="selected_nav_tab === 'TransferPatientDataPersonnelIdList' ? 'color: green;' : ''">
                    <i class="fa fa-edit"></i> Hasta bilgileri HBYSden transferi
                  </div>
                </b-nav-item>
                <b-nav-item v-if="hospital_data.id" @click="selected_nav_tab = 'TransferOperations'" :active="selected_nav_tab === 'TransferOperations'">
                  <div :style="selected_nav_tab === 'TransferOperations' ? 'color: green;' : ''">
                    <i class="fa fa-edit"></i> Hasta verisi trasfer işlemleri
                  </div>
                </b-nav-item>
              </b-nav>
            </b-card>
          </b-col>
          <b-col sm="12" md="6" lg="9">
            <template v-if="selected_nav_tab === 'WisdomDataModal'">
              <parameter-design :page_type="'hospital_settings'" :hospital_settings_data="hospital_data"></parameter-design>
            </template>
            <b-card no-body v-if="['WisdomDataModal'].indexOf(selected_nav_tab) === -1">
              <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                <i class="fa fa-info-circle"></i> Detaylar
              </b-card-header>
              <b-card-body>
                <template v-if="selected_nav_tab === 'TransferOperations'">
                  <b-row>
                    <b-col sm="12" md="6" lg="5">
                      <b-card no-body>
                        <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1"><i class="fa fa-gears"></i> Veri transfer kayıtlı işlemler </b-card-header>
                          <b-nav vertical>
                        <div style="overflow-y: auto; overflow-x: hidden; height: 500px;">
                            <template v-for="(wdmr224, wdmr224_ind) in d_wdmr224List">
                              <b-nav-item @click="f_selectWdmr224(wdmr224.key)" :active="wdmr224.key === d_selectedWdmr224key">
                                <div :style="wdmr224.key === d_selectedWdmr224key ? 'color: green;' : ''">
                                  <b-row>
                                    <b-col sm="12" md="10">
                                      <i class="fa fa-handshake-o"></i> {{ wdmr224.label }} [{{ wdmr224.key }}]
                                    </b-col>
                                    <b-col sm="12" md="2">
                                      <b-dropdown class="pull-right" variant="secondary" text="İşlemler" size="sm" v-if="wdmr224.key === d_selectedWdmr224key">
                                        <b-dropdown-item v-if="d_selectedWdmr224Data && d_selectedWdmr224Data.id" @click="f_editChildWdmr('wdm224')"> düzenle </b-dropdown-item>
                                        <b-dropdown-item v-if="d_selectedWdmr224Data && d_selectedWdmr224Data.id && f_checkIfWdmr224Startable(d_selectedWdmr224Data)" @click="f_startWdmr224Operations(d_selectedWdmr224key)"> çekim işlemlerini başlat </b-dropdown-item>
                                      </b-dropdown>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-nav-item>
                            </template>
                        </div>
                          </b-nav>
                      </b-card>
                    </b-col>
                    <b-col sm="12" md="6" lg="7">
                      <template v-if="d_selectedWdmr224Data && d_selectedWdmr224Data.id">
                        <b-card no-body>
                          <b-card-header header-bg-variant="sedondary" header-text-variant="dark" class="p-1">
                            {{ d_selectedWdmr224Data.label }}
                          </b-card-header>
                          <div style="overflow-y: auto; overflow-x: hidden; height: 500px;">
                            <wisdom-data-show :change="d_wisdomData.change" :view_mode="d_wisdomData.view_mode" :data="d_selectedWdmr224Data" :data_type="'wdm224'" :option_data="d_wisdomData.option_data['wdm224']"></wisdom-data-show>
                          </div>
                        </b-card>
                      </template>
                    </b-col>
                  </b-row>
                </template>
                <template v-if="selected_nav_tab === 'TransferPatientDataPersonnelIdList'">
                  <b-row>
                    <b-col cols="12">
                      <b-card no-body>
                        <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                          <b-row>
                            <b-col cols="3">
                              Başlangıç:
                            </b-col>
                            <b-col cols="3">
                              <b-form-input type="date" id="dateFrom" v-model="d_dateOptions.dateFrom"></b-form-input>
                            </b-col>
                            <b-col cols="3">
                              Bitiş:
                            </b-col>
                            <b-col cols="3">
                              <b-form-input type="date" id="dateTo" v-model="d_dateOptions.dateTo"></b-form-input>
                            </b-col>
                          </b-row>
                        </b-card-header>
                      </b-card>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" md="6" lg="5">
                      <b-card no-body>
                        <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1"><i class="fa fa-gears"></i> Eşleştirilmiş branşlar </b-card-header>
                        <b-nav vertical>
                          <template v-for="(b, b_ind) in d_hospitalMatchedBranchIdList">
                            <b-nav-item @click="d_selectedBranchTab = b" :active="d_selectedBranchTab.value === b.value">
                              <div :style="d_selectedBranchTab.value === b.value ? 'color: green;' : ''">
                                <i class="fa fa-handshake-o"></i> {{ b.label }}
                                <b-dropdown class="pull-right" variant="secondary" text="İşlemler" size="sm" v-if="d_selectedBranchTab.value === b.value">
                                  <b-dropdown-item v-if="d_selectedBranchTab.hims_branch_id !== ''" @click="f_addNewWdmr224('branch')"><i class="fa fa-plus"></i>Bu branşa ait personellere ait hastaların tüm verilerini wisdomeraya transfer et</b-dropdown-item>
                                </b-dropdown>
                              </div>
                            </b-nav-item>
                          </template>
                        </b-nav>
                      </b-card>
                    </b-col>
                    <b-col sm="12" md="6" lg="7">
                      <b-card no-body v-if="d_selectedBranchTab.value">
                        <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1"><i class="fa fa-gears"></i> Branşa eşleştirilen personeller </b-card-header>
                        <table style="border: solid 1px black; width: 100%;">
                          <thead>
                            <tr>
                              <th>kullanıcı adı</th>
                              <th>ad</th>
                              <th>soyad</th>
                              <th>personel id</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(u, u_ind) in d_branchMatchedPersonnels" style="border: solid 1px black;">
                              <td>{{ u.username }}</td>
                              <td>{{ u.first_name }}</td>
                              <td>{{ u.last_name }}</td>
                              <td>{{ u.personnelId === '' ? 'personel id tanımlanmamış' : u.personnelId }}</td>
                              <td>
                                <b-button v-if="u.personnelId" @click="f_addToPersonnelList(u)" variant="success" size="sm">+</b-button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </b-card>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" md="12" lg="12">
                      <b-card no-body>
                        <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1"><i class="fa fa-gears"></i> Seçili personeller
                          <b-dropdown class="pull-right" variant="secondary" text="İşlemler" size="sm">
                            <b-dropdown-item @click="f_addNewWdmr224('personnel')"><i class="fa fa-plus"></i>Seçili personeller için veri transfer dökümanı hazırla </b-dropdown-item>
                          </b-dropdown>
                        </b-card-header>
                        <table style="border: solid 1px black; width: 100%;">
                          <thead>
                            <tr>
                              <th>kullanıcı adı</th>
                              <th>ad</th>
                              <th>soyad</th>
                              <th>personel id</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(u, u_ind) in d_selectedPersonnelList" style="border: solid 1px black;">
                              <td>{{ u.username }}</td>
                              <td>{{ u.first_name }}</td>
                              <td>{{ u.last_name }}</td>
                              <td>{{ u.personnelId === '' ? 'personel id tanımlanmamış' : u.personnelId }}</td>
                              <td>
                                <b-button @click="f_deletePersonnel(u_ind)" variant="danger" size="sm">-</b-button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </b-card>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" md="12" lg="12">
                      <b-card no-body>
                        <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1"><i class="fa fa-gears"></i> Hastane Genelinden Data Çekim Çalışması
                        </b-card-header>
                        <b-col cols="12">
                          <b-button block variant="warning" @click="f_addNewWdmr224('hospital')"> Hastane Geneli İçin Transfer Dökümanı Hazırla</b-button>
                        </b-col>
                      </b-card>
                    </b-col>
                  </b-row>
                </template>
                <template v-if="selected_nav_tab === 'UnitDefinitions'">
                  <b-card no-body>
                    <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
                      HIMS Unit Definitions
                    </b-card-header>
                    <table>
                      <tr>
                        <th>ID</th>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Operation</th>
                      </tr>
                      <template v-for="(hims_definition, hims_definition_ind) in d_himsUnitDefinitions">
                        <tr v-if="f_showThisHimsUnitDefinition(hims_definition)" :key="'hims_definition_' + hims_definition_ind">
                          <td>{{ hims_definition.id }}</td>
                          <td>{{ hims_definition.code }}</td>
                          <td>{{ hims_definition.name }}</td>
                          <td>
                            <b-button small variant="primary" @click="f_addHimsDefinitionToHospital(hims_definition, 'measurable')">
                              <li class="fa fa-plus"></li> Ölçülebilir
                            </b-button>&nbsp;
                            <b-button small variant="success" @click="f_addHimsDefinitionToHospital(hims_definition, 'countable')">
                              <li class="fa fa-plus"></li> Sayılabilir
                            </b-button>
                          </td>
                        </tr>
                      </template>
                    </table>
                  </b-card>
                  <b-card no-body>
                    <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
                      WisdomEra Unit Definitions
                    </b-card-header>
                    <table>
                      <tr>
                        <th>Value</th>
                        <th>Label</th>
                        <th>Type</th>
                        <th>Operation</th>
                      </tr>
                      <template v-for="(unit_definition, unit_definition_ind) in hospital_data.unit_definitions">
                        <tr :key="'unit_definition_' + unit_definition_ind">
                          <td>{{ unit_definition.value }}</td>
                          <td>{{ unit_definition.label }}</td>
                          <template v-if="unit_definition.type === 'measurable'">
                            <td><span style="color: red; font-weight: bold;">Ölçülebilir</span></td>
                          </template>
                          <template v-else>
                            <td><span style="color: blue; font-weight: bold;">Sayılabilir</span></td>
                          </template>
                          <td>
                            <b-button small variant="danger" @click="f_removeUnitDefintion(unit_definition_ind)">
                              <li class="fa fa-trash"></li> Kaldır
                            </b-button>
                          </td>
                        </tr>
                      </template>
                    </table>
                  </b-card>
                </template>
                <template v-if="selected_nav_tab === 'hospital_code'">
                  <b-form-group label="Hastane Kodu" label-for="hospital_code" :label-cols="3" description="">
                    {{ hospital_data.hospital_id }}
                  </b-form-group>
                </template>
                <template v-if="selected_nav_tab === 'address'">
                  <b-form-group label="Adres" label-for="address" :label-cols="3" description="">
                    <b-form-input v-model="hospital_data.address"></b-form-input>
                  </b-form-group>
                </template>
                <template v-if="selected_nav_tab === 'WisdomTreeList'">
                  <b-card header-tag="header" footer-tag="footer" no-body>
                    <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
                      Wisdom Tree List (WTL) modelleri
                    </b-card-header>
                    <b-row v-for="(tree_model, tree_model_ind) in hospital_wtl_list" :key="'wtl-' + tree_model_ind" style="border-bottom: solid 1px #bca3a3; padding: 3px; margin: 0px;">
                      <b-col cols="12">
                        <strong>
                          <i class="fa fa-hand-paper-o" style="color: red;"></i>
                          {{ tree_model.name.label }}
                          <small style="color: green; margin-right: 10px;">[<i>{{ tree_model.parent }}</i>]</small>
                          <small :style="tree_model.isTransfered ? 'color: green;' : 'color: red;'">[<i>{{ tree_model.isTransfered ? 'Transfer Edildi.' : 'Henüz Transfer Edilmedi' }}</i>]</small>
                        </strong>
                        <b-row>
                          <b-col cols="8" @click="f_selectWtl(tree_model_ind)">
                            <div :class="[CalculateWtlSelectedModelClass(tree_model_ind)]">
                              <!-- versiyon bilgisi şu an manuel 1 olarak atandı. Daha sonra versiyonlanabilir. -->
                              <i class="fa fa-hand-o-right" style="color: green;"></i> Versiyon - 1
                            </div>
                          </b-col>
                          <b-col cols="4">
                            <b-dropdown class="pull-right" variant="secondary" text="Model İşlemleri" size="sm" v-if="d_selectedWtlIndex === tree_model_ind">
                              <b-dropdown-item v-if="!tree_model.isTransfered" @click="f_activateWtlModel(tree_model_ind)"><i class="fa fa-plus"></i>Veri Ağacını Aktive Et</b-dropdown-item>
                            </b-dropdown>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-card>
                  <b-card v-if="d_selectedWtlIndex !== '' && hospital_wtl_list[d_selectedWtlIndex].isTransfered && d_showPage" header-tag="header" footer-tag="footer" no-body :class="[wtl_full_screen ? 'wtl-full-screen' : 'not-wtl-full-screen']">
                    <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
                      {{ hospital_wtl_list[d_selectedWtlIndex].name.label }}
                      <b-button style="padding-right: 5px;" size="sm" class="pull-right" variant="secondary" @click="wtl_full_screen ? wtl_full_screen = false : wtl_full_screen = true;"> <i class="fa fa-arrows"></i>{{ $t('wdm16.6589') }}</b-button>
                    </b-card-header>
                    <tree-list :show_mode="tree_list.wtl.show_mode" :tree_list_type="hospital_wtl_list[d_selectedWtlIndex].parent" :location="'hospital'" :select_type="tree_list.wtl.select_type" :mode="tree_list.wtl.mode" :selected_items_data_list="tree_list.wtl.selected_items_data_list" :hospital_data="hospital_data" :hospital_group="hospital_data.hospital_group" :hospital_id="hospital_data.hospital_id"></tree-list>
                  </b-card>
                </template>
                <template v-if="selected_nav_tab === 'WisdomDataModalList'">
                  <b-card header-tag="header" footer-tag="footer" no-body>
                    <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
                      WisdomEra Modelleri
                    </b-card-header>
                    <b-row v-for="(model, model_ind) in hospital_wdm_list.wisdomera" :key="'widomera-' + model_ind" style="border-bottom: solid 1px #bca3a3; padding: 3px; margin: 0px;">
                      <b-col cols="12">
                        <strong>
                          <i class="fa fa-hand-paper-o" style="color: red;"></i>
                          {{ model.name.label }}
                          <small style="color: green; margin-right: 10px;">[<i>{{ model_ind }}</i>]</small>
                          <small :style="model.isTransfered ? 'color: green;' : 'color: red;'">[<i>{{ model.isTransfered ? 'Transfer Edildi.' : 'Henüz Transfer Edilmedi' }}</i>]</small>
                        </strong>
                        <b-row v-for="(model_ver, model_ver_ind) in model.list" :key="'widomera-' + model_ind + model_ver_ind">
                          <b-col cols="8" @click="SelectModel(model, model_ind, model_ver, model_ver_ind, 'wisdom')">
                            <div :class="[CalculateSelectedModelClass('wisdom', model_ind, model_ver_ind)]">
                              <i class="fa fa-hand-o-right" style="color: green;"></i> Version - {{ model_ver.version }}
                            </div>
                          </b-col>
                          <b-col cols="4">
                            <b-dropdown class="pull-right" variant="secondary" text="Model İşlemleri" size="sm" v-if="d_selectedModelIndex === model_ind && d_selectedLocation ==='wisdom' && d_selectedModelVersionIndex === model_ver_ind">
                              <b-dropdown-item v-if="!model.isTransfered" @click="f_activateThisModel()"><i class="fa fa-plus"></i>Veri Modelini Aktive Et</b-dropdown-item>
                            </b-dropdown>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-card>
                  <b-card header-tag="header" footer-tag="footer" no-body>
                    <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
                      Kullanıcı Modelleri
                    </b-card-header>
                    <b-row v-for="(model, model_ind) in hospital_wdm_list.user" :key="'user-' + model_ind" style="border-bottom: solid 1px #bca3a3; padding: 3px; margin: 0px;">
                      <b-col cols="12">
                        <strong>
                          <i class="fa fa-hand-paper-o" style="color: red;"></i>
                          {{ model.name.label }} <small style="color: green;">[<i>{{ model_ind }}</i>]</small>
                        </strong>
                        <b-row v-for="(model_ver, model_ver_ind) in model.list" :key="'user-' + model_ind + model_ver_ind">
                          <b-col cols="8" @click="SelectModel(model, model_ind, model_ver, model_ver_ind, 'user')">
                            <div :class="[CalculateSelectedModelClass('user', model_ind, model_ver_ind)]">
                              <i class="fa fa-hand-o-right" style="color: green;"></i> Version - {{ model_ver.version }}
                            </div>
                          </b-col>
                          <b-col cols="4">
                            <b-dropdown class="pull-right" variant="secondary" text="Model İşlemleri" size="sm" v-if="d_selectedModelIndex === model_ind && d_selectedLocation ==='user' && d_selectedModelVersionIndex === model_ver_ind">
                              <b-dropdown-item v-if="!model.isTransfered" @click="f_activateThisModel()"><i class="fa fa-plus"></i>Veri Modelini Aktive Et</b-dropdown-item>
                            </b-dropdown>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-card>
                  <!-- 
                  <b-row v-for="(model, model_ind) in hospital_wdm_list">
                    <b-col sm="12" md="12" lg="6"> {{ model.name.label }} </b-col>
                    <b-col sm="12" md="12" lg="6">
                      <template v-if="model.isTransfered && model.isTransfered === 1">
                        <span style="color: green;">Wisdom Veri Modeli bu hastanede aktive edilmiştir.</span>
                      </template>
                      <template v-else>
                        <b-button variant="success" @click="f_activateThisModel(model_ind)">modeli hastanemizde aktive et</b-button>
                      </template>
                    </b-col>
                  </b-row> -->
                </template>
                <template v-if="selected_nav_tab === 'center_phone_number'">
                  <b-form-group label="Merkez Telefonu" label-for="center_phone_number" :label-cols="3" description="">
                    <b-form-input v-model="hospital_data.center_phone_number"></b-form-input>
                  </b-form-group>
                </template>
                <template v-if="selected_nav_tab === 'logos'">
                  <b-form-group label="" label-for="logos" :label-cols="0" description="">
                    <div class="card" v-for="logo in logo_list">
                      <div class="card-header"> {{ logo }} </div>
                      <div class="card-block">
                        <input :id="logo" type="file" ref="fileinput" @change="onFileChange(logo, 'logo')">
                        <img :src="hospital_data.logo[logo]" style="width: 100px; height: 100px;">
                      </div>
                    </div>
                  </b-form-group>
                </template>
                <template v-if="selected_nav_tab === 'wisdomera_support'">
                  <b-form-group label="" label-for="wisdomera_support" :label-cols="0" description="">
                    <b-button class="pull-right" size="sm" variant="primary" @click="f_addWisdomEraSupport()"><i class="fa fa-add"></i> yeni sorumlu ekle</b-button>
                    <br>
                    <div class="card" v-for="wera_support, wera_support_ind in hospital_data.wisdomera_support">
                      <div class="card-header">
                        {{ hospital_data.wisdomera_support[wera_support_ind].name }}
                        <b-button class="pull-right" variant="danger" size="sm" @click="f_removeWisdomEraSupport(wera_support_ind)"><i class="fa fa-trash"></i></b-button>
                      </div>
                      <div class="card-block">
                        <b-row>
                          <b-col cols="4">
                            <input :id="'wera_support_' + wera_support_ind" type="file" ref="fileinput" @change="onFileChange('wera_support_' + wera_support_ind, 'wera_support', wera_support_ind)">
                            <img :src="hospital_data.wisdomera_support[wera_support_ind].image" style="width: 100px; height: 100px;">
                          </b-col>
                          <b-col cols="8">
                            <b-form-group label="Sorumlu Adı" label-for="wisdomera_support.name" :label-cols="3" description="">
                              <b-form-input v-model="hospital_data.wisdomera_support[wera_support_ind].name"></b-form-input>
                            </b-form-group>
                            <b-form-group label="Sorumluluğu" label-for="wisdomera_support.position" :label-cols="3" description="">
                              <b-form-input v-model="hospital_data.wisdomera_support[wera_support_ind].position"></b-form-input>
                            </b-form-group>
                            <b-form-group label="Telefon Numarası" label-for="wisdomera_support.phone" :label-cols="3" description="">
                              <b-form-input v-model="hospital_data.wisdomera_support[wera_support_ind].phone"></b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </b-form-group>
                </template>
                <template v-if="selected_nav_tab === 'captions'">
                  <b-form-group label="Hastane Liste Başlığı" label-for="hospital_name" :label-cols="3" description="">
                    <b-form-input v-model="hospital_data.hospital_name"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Hastane Adı (TR)" label-for="hospital_name_tr" :label-cols="3" description="">
                    <b-form-input v-model="hospital_data.headers.hospital_name.translation.tr"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Hastane Adı (EN)" label-for="hospital_name_en" :label-cols="3" description="">
                    <b-form-input v-model="hospital_data.headers.hospital_name.translation.en"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Kemoterapi Protokol Şeması (TR)" label-for="chemotherapy_protocol_scheme_tr" :label-cols="3" description="">
                    <b-form-input v-model="hospital_data.headers.chemotherapy_protocol_scheme.translation.tr"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Kemoterapi Protokol Şeması (EN)" label-for="chemotherapy_protocol_scheme_en" :label-cols="3" description="">
                    <b-form-input v-model="hospital_data.headers.chemotherapy_protocol_scheme.translation.en"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Reçete İlaçlar Modülü (TR)" label-for="recipe_drugs_module_tr" :label-cols="3" description="">
                    <b-form-input v-model="hospital_data.headers.recipe_drugs_module.translation.tr"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Reçete İlaçlar Modülü (EN)" label-for="recipe_drugs_module_en" :label-cols="3" description="">
                    <b-form-input v-model="hospital_data.headers.recipe_drugs_module.translation.en"></b-form-input>
                  </b-form-group>
                </template>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
    </b-card>
    <modal v-if="d_wisdomData.show" @close="d_wisdomData.show = false" :width="'750'">
      <h3 slot="header">{{ d_wisdomData.option_data[d_wisdomData.data_type].name.label }}</h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="d_wisdomData.wisdom_data" :data_type="d_wisdomData.data_type" :option_data="d_wisdomData.option_data"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveWdm()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_wisdomData.show = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>
<script>
  import {
  default as WisdomDataShow
} from '@/components/widgets/WisdomDataShow';
import {
  default as Modal
} from '@/components/widgets/Modal';
// import { mapGetters } from 'vuex';
// import {default as router} from '@/router';
import { ClsWdmrManage } from "@/services/public/wdmr_manage";
import GlobalService from '@/services/global';
import {
  default as ParameterDesign
} from '@/components/widgets/ParameterDesign';
import WebService from '@/services/webservice';
import HospitalService from '@/services/hospital';
import OptionService from '@/services/option';
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import {
  default as TreeList
} from '@/components/widgets/TreeList';

export default {
  name: 'HospitalData',
  computed: {},
  props: {
    hospital_data: {
      type: Object,
      required: true
    },
    hospital_wdm_list: {
      type: Object,
      required: true
    },
    hospital_wtl_list: {
      type: Array,
      required: true
    },
    all_hospital: {
      type: Boolean,
      required: false
    }
  },
  components: {
    ParameterDesign,
    TreeList,
    WisdomDataModal,
    WisdomDataShow,
    Modal
  },
  data () {
    return {
      d_selectedWdmr224Data: '',
      d_selectedPersonnelList: [],
      d_wisdomData: {
        'view_mode': 'table',
        'change': '0',
        'show': false,
        'wisdom_data': {},
        'data_type': '',
        'wisdom_op': '',
        'option_data': {}
      },
      d_wdmr224List: [],
      d_selectedWdmr224key: '',
      d_dateOptions: {
        'dateFrom': '',
        'dateTo': ''
      },
      d_branchMatchedPersonnels: [],
      d_hospitalMatchedBranchIdList: [],
      tree_list: {
        'wtl': {
          'show_items_on_top': false,
          'max_height': '300',
          'selected_items_data_list': [],
          'mode': 'edit', // ['edit', 'use']
          'show_mode': 'treeView',
          'select_type': 'one' // ['one', 'multi']
        }
      },
      d_selectedBranchTab: {},
      selected_nav_tab: 'hospital_code',
      user: {},
      selected_group_ind: '',
      selected_hospital_ind: '',
      hospital_group_data: {},
      edit_hospital_in_group: { 'caption': '', 'label': '' },
      edit_hospital_ind: '',
      hospital_group_list: [],
      hospital_group: { 'caption': '', 'label': '', 'hospital_list': [] },
      new_hospital: { 'caption': '', 'label': '' },
      edit_hospital_group: { 'caption': '', 'label': '' },
      edit_hospital_group_ind: '',
      logo_list: ['logo_1', 'logo_2', 'logo_3', 'logo_4', 'logo_5'],
      selected_image: '',
      selected_file: '',
      department_manager: '',
      selected_dep_ind: '',
      department: '',
      person: '',
      telephone: '',
      option_data: {},
      selected_data_type: '',
      d_selectedModelIndex: '',
      d_selectedModelVersionIndex: '',
      d_selectedLocation: '',
      d_selectedModel: {},
      d_selectedWtlIndex: '',
      wtl_full_screen: false,
      d_showPage: false,
      d_himsUnitDefinitions: [],
      d_wdm245_2_keys: '',
      d_wdm225_key: ''
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (!this.hospital_data.wisdomera_support) {
      this.hospital_data.wisdomera_support = [];
    }
    if (!this.hospital_data.hospital_name) {
      this.hospital_data.hospital_name = '';
    }
    if (!this.hospital_data.unit_definitions) {
      this.hospital_data.unit_definitions = [];
    }
  },
  mounted: function () {
    this.f_wdmList();
    this.f_getHospitalWdm245_2_keys();
  },
  methods: {
    f_getHospitalWdm245_2_keys: function (wdmr_key) {
      let data = { 'key': 'wdm245_2_keys', 'bucket': 'wisdom' };
      GlobalService.get_one_cb_document(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            this.d_wdm245_2_keys = resp.data.result;
            for (let i in this.d_wdm245_2_keys.wdm.wdm225.list) {
              if (this.d_wdm245_2_keys.wdm.wdm225.list[i].hospital_id === this.hospital_data.hospital_id) {
                this.d_wdm225_key = this.d_wdm245_2_keys.wdm.wdm225.list[i].key;
                break;
              }
            }
          } else {
            this.d_wdm245_2_keys = '',
              console.log('errorr f_getWdmrData : ', resp.data.message);
          }
        });
    },
    f_startWdmr224Operations: function (wdm224_key) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İşlem başlatılıyor' } });
      let data = {
        'wdm224_key': wdm224_key,
        'wdm225_key': this.d_wdm225_key
      };
      HospitalService.transfer_all_patients_of_selected_personnel_ids_from_hims_to_wisdomera(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_selectedWdmr224Data = resp.data.result;
            let modal_data = {
              'type': 'alert',
              'header_bg_variant': 'info',
              'header_text_variant': 'dark',
              'text': 'Güncelleme işlemleri başlatıldı, her doktora ait 7 günlük data güncellendikçe ekrandaki görüntülenebilecek bilgi güncellenecektir',
              'centered': true,
              'title': 'Hasta kayıt ve mevcut hasta bilgilerinin güncelleme işlemi hakkında'
            };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          } else {
            alert('error: ' + resp.data.message);
          }
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
      // body...
    },
    f_checkIfWdmr224Startable: function (wdm224_data) {
      try {
        if (wdm224_data.data.general.status.val.value === '' || wdm224_data.data.general.status.val.value === 'not_started') {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        return true;
      }
    },
    f_selectWdmr224: function (wdmr_key) {
      this.d_selectedWdmr224Data = '';
      this.d_selectedWdmr224key = wdmr_key;
      // Examples: wdmr_key => wdm7_1, wdm7_2, ......
      let data = { 'key': wdmr_key, 'bucket': 'wisdom' };
      GlobalService.get_one_cb_document(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            this.d_selectedWdmr224Data = resp.data.result;
          } else {
            this.d_selectedWdmr224Data = '';
            console.log('errorr f_getWdmrData : ', resp.data.message);
          }
        });
    },
    f_editChildWdmr: function (data_type) {
      this.d_wisdomData.wisdom_op = "edit";
      this.d_wisdomData.data_type = data_type;
      this.d_wisdomData.wisdom_data = JSON.parse(JSON.stringify(this.d_selectedWdmr224Data));
      this.d_wisdomData.show = true;
    },
    f_saveWdm: function () {
      let parent_wdm = '';
      let child_wdm = '';
      let relation_type = 'keys';
      if (this.d_wisdomData.data_type === 'wdm224') {
        parent_wdm = {
          'label': '',
          'key': this.d_wdm225_key,
          'bucket': 'wisdom',
          'type': 'wdm225'
        };
        child_wdm = {
          'label': '',
          'key': 'wdm_wisdom_wdm224_v2.0',
          'bucket': 'wisdom',
          'type': 'wdm224'
        };
        relation_type = 'keys';
      }
      ClsWdmrManage.save_child_wdmr(this.d_wisdomData, parent_wdm, child_wdm, relation_type)
        .then(resp => {
          // console.log(resp);
          if (this.d_wisdomData.data_type === 'wdm224') {
            // this.d_selectedWdmrData = resp.wdmr;
            // this.wisdom_show_update();
          }
          alert('Successfully recorded');
        }, resp => {
          alert('error ', resp);
        });
    },
    f_addNewWdmr224: function (op_type) {
      if (this.d_dateOptions.dateFrom && this.d_dateOptions.dateTo) {
        ClsWdmrManage.prepare_new_wisdom_data(this.d_wisdomData, 'wdm224');

        this.d_wisdomData.wisdom_data.data.general.status = {
            'val': {
              'value': 'not_started',
              'label': 'henüz başlatılmadı'
            }
          }
          // console.log(this.d_wisdomData.wisdom_data);
        if (op_type === 'personnel') {
          this.d_wisdomData.wisdom_data.data.general.filter_type = {
            'val': { 'value': 'personnel', 'label': 'Personel Bazlı' }
          };
        } else if (op_type === 'branch') {
          this.d_wisdomData.wisdom_data.data.general.filter_type = {
            'val': { 'value': 'branch', 'label': 'Branş Bazlı' }
          };
        } else if (op_type === 'hospital') {
          this.d_wisdomData.wisdom_data.data.general.filter_type = {
            'val': { 'value': 'hospital', 'label': 'Hastane Geneli' }
          };
        }
        this.d_wisdomData.wisdom_data.data.general.start_date = {
          'val': this.d_dateOptions.dateFrom
        };
        this.d_wisdomData.wisdom_data.data.general.ending_date = {
          'val': this.d_dateOptions.dateTo
        };
        this.d_wisdomData.wisdom_data.data.general.hospital_group = {
          'val': this.hospital_data.hospital_group
        };
        this.d_wisdomData.wisdom_data.data.general.hospital_id = {
          'val': this.hospital_data.hospital_id
        };
        if (op_type === 'personnel') {

          for (let i in this.d_selectedPersonnelList) {
            let list_item = {
              'personnel_name': {
                'val': this.d_selectedPersonnelList[i].first_name + ' ' + this.d_selectedPersonnelList[i].last_name
              },
              'personnel_id': {
                'val': this.d_selectedPersonnelList[i].personnelId
              },
              'branch_label': {
                'val': this.d_selectedPersonnelList[i].branch_label
              },
              'patient_count': {
                'val': '0'
              },
              'protocol_inspection_count': {
                'val': '0'
              }
            }
            if (!this.d_wisdomData.wisdom_data.data.general.personnel_list) {
              this.d_wisdomData.wisdom_data.data.general.personnel_list = { 'list': [] };
            }
            this.d_wisdomData.wisdom_data.data.general.personnel_list.list.push(list_item);
          }
        } else if (op_type === 'branch') {
          this.d_wisdomData.wisdom_data.data.general.selected_branch = {
            'val': {
              'value': this.d_selectedBranchTab.hims_branch_id,
              'label': this.d_selectedBranchTab.label
            }
          }
        }
        // this.d_wisdomData.show = true;
        this.f_saveWdm();
      } else {
        alert('Lütfen tarih seçimlerini yapınız.');
      }
    },
    f_deletePersonnel: function (u_ind) {
      this.d_selectedPersonnelList.splice(u_ind, 1);
      this.$forceUpdate();
    },
    f_addToPersonnelList: function (personnel_data) {
      let det = false;
      for (let i in this.d_selectedPersonnelList) {
        if (this.d_selectedPersonnelList[i].personnelId === personnel_data.personnelId) {
          det = true;
        }
      }
      if (!det) {
        let branch_label = '';
        for (let k in this.d_hospitalMatchedBranchIdList) {
          if (this.d_hospitalMatchedBranchIdList[k].value === this.d_selectedBranchTab.value) {
            branch_label = this.d_hospitalMatchedBranchIdList[k].label;
            break;
          }
        }
        personnel_data.branch_label = branch_label;
        this.d_selectedPersonnelList.push(personnel_data);
      } else {
        alert('This personnel is already added');
      }
    },
    f_wdmList: function () {
      let data = {
        'key_list': ['wdm_wisdom_wdm224_v2.0'],
        'bucket': 'option'
      };
      GlobalService.get_multiple_cb_documents(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            for (let i in resp.data.result) {
              this.d_wisdomData.option_data[resp.data.result[i].parent] = resp.data.result[i];
            }
          } else {
            console.log('error ', resp.data.message);
          }
        });
    },
    f_getWdmr224List: function () {
      this.d_wdmr224List = [];
      let parent_wdm = {
        'label': '',
        'key': this.d_wdm225_key,
        'bucket': 'wisdom',
        'type': 'wdm225'
      };
      let child_wdm = {
        'label': '',
        'bucket': 'wisdom',
        'type': 'wdm224'
      };
      let relation_type = 'keys';
      ClsWdmrManage.get_related_wdmr_list(parent_wdm, child_wdm, relation_type)
        .then(resp => {
          if (resp && resp.list) {
            this.d_wdmr224List = resp.list;
          }
        }, resp => {
          console.log('error ', resp);
        });
    },
    f_transferAllPatientsOfThisBranchFromHimsToWisdomera: function () {
      if (!this.d_dateOptions.dateFrom || !this.d_dateOptions.dateTo) {
        alert('Lütfen tarih seçimini yapınız');
      } else {
        let eligible_personnels = [];
        for (let i in this.d_branchMatchedPersonnels) {
          if (this.d_branchMatchedPersonnels[i].personnelId !== '') {
            eligible_personnels.push(this.d_branchMatchedPersonnels[i]);
          }
        }
        if (eligible_personnels.length > 0) {
          let data = {
            'personnels': eligible_personnels,
            'hospital_group': this.hospital_data.hospital_group,
            'hospital_id': this.hospital_data.hospital_id,
            'dateFrom': this.d_dateOptions.dateFrom,
            'dateTo': this.d_dateOptions.dateTo,
            'protocolTypeID': '0'
          };
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Branş ve personel idsi eşleştirilmiş hekimlere ait hastaların tüm verileri kaydediliyor.' } });
          HospitalService.transfer_all_patients_of_this_branch_from_hims_to_wisdomera(data)
            .then(resp => {
              if (resp.data.status === 'success') {
                let modal_data = {
                  'type': 'alert',
                  'header_bg_variant': 'info',
                  'header_text_variant': 'dark',
                  'text': 'Güncelleme işlemleri başlatıldı, her 50 hastada bir ekranınıza bilgi verilecektir',
                  'centered': true,
                  'title': 'Hasta kayıt ve mevcut hasta bilgilerinin güncelleme işlemi hakkında'
                };
                this.$store.commit('modal', modal_data);
                this.$store.commit('modal_show', true);
              } else {
                alert('error: ', resp.data.message);
              }
              this.$store.commit('Loading', { 'status': false, 'data': {} });
            });
        } else {
          alert('Bu branşla eşleştirilmiş personellerin personel eşleştirme işlemi yapılmamış');
        }
      }
    },
    f_showThisHimsUnitDefinition: function (hims_definition) {
      for (let i in this.hospital_data.unit_definitions) {
        if (this.hospital_data.unit_definitions[i].value === hims_definition.id) {
          return false;
        }
      }
      return true;
    },
    f_removeUnitDefintion: function (unit_definition_ind) {
      this.hospital_data.unit_definitions.splice(unit_definition_ind, 1);
      this.$forceUpdate();
    },
    f_addHimsDefinitionToHospital: function (hims_definition, definition_type) {
      let tmp_data = { 'value': hims_definition.id, 'label': hims_definition.name, 'type': definition_type };
      let det = false;
      for (let i in this.hospital_data.unit_definitions) {
        if (this.hospital_data.unit_definitions[i].value === tmp_data.value) {
          this.hospital_data.unit_definitions[i] = JSON.parse(JSON.stringify(tmp_data));
          det = true;
          break;
        }
      }
      if (!det) {
        this.hospital_data.unit_definitions.push(tmp_data);
      }
      this.$forceUpdate();
    },
    f_removeWisdomEraSupport: function (wera_support_ind) {
      this.hospital_data.wisdomera_support.splice(wera_support_ind, 1);
      this.$forceUpdate();
    },
    f_addWisdomEraSupport: function () {
      let append_data = { 'image': '', 'phone': '', 'name': '', 'position': '' };
      this.hospital_data.wisdomera_support.push(append_data);
      this.$forceUpdate();
    },
    f_showTrueFalse: function () {
      this.d_showPage = false;
      setTimeout(function () {
        this.d_showPage = true;
      }.bind(this), 1000)
    },
    f_activateWtlModel: function (tree_model_ind) {
      let data = {
        'data_type': this.hospital_wtl_list[tree_model_ind]['parent'],
        'hospitalGroup': this.hospital_data.hospital_group,
        'hospitalCode': this.hospital_data.hospital_id
      };
      OptionService.activateWtlModel(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.hospital_wtl_list[tree_model_ind].isTransfered = 1;
          } else {
            alert('error:', resp.data.message);
          }
        });
    },
    f_selectWtl: function (tree_model_ind) {
      this.f_showTrueFalse();
      this.d_selectedWtlIndex = tree_model_ind;
    },
    SelectModel: function (model, model_ind, model_ver, model_ver_ind, location) {
      this.d_selectedLocation = location;
      this.d_selectedModelIndex = model_ind;
      this.d_selectedModelVersionIndex = model_ver_ind;
      this.d_selectedModel = model;
      this.d_selectedModel.version = model_ver.version;
      this.d_selectedModel.key = model_ver.key;
    },
    CalculateWtlSelectedModelClass: function (tree_model_ind) {
      if (this.d_selectedWtlIndex === tree_model_ind) {
        return 'model-selected';
      } else {
        return 'model-not-selected';
      }
    },
    CalculateSelectedModelClass: function (location, model_ind, model_ver_ind) {
      if (this.d_selectedModelIndex === model_ind && this.d_selectedModelVersionIndex === model_ver_ind && this.d_selectedLocation === location) {
        return 'model-selected';
      } else {
        return 'model-not-selected';
      }
    },
    f_activateThisModel: function () {
      let data = {
        'key': this.d_selectedModel.key,
        'location': this.d_selectedLocation,
        'hospitalGroup': this.hospital_data.hospital_group,
        'hospitalCode': this.hospital_data.hospital_id
      };
      OptionService.activateDataModel(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.hospital_wdm_list[this.d_selectedLocation][this.d_selectedModelIndex].isTransfered = 1;
          } else {
            alert('error:', resp.data.message);
          }
        });
    },
    SaveEditHospital_ind_group: function () {
      this.hospital_group_list[this.edit_hospital_group_ind].hospital_list[this.edit_hospital_ind] = JSON.parse(JSON.stringify(this.edit_hospital_in_group));
      this.edit_hospital_in_group = '';
    },
    EditHospitalInGroup (hos_grp_ind, hos, hos_ind) {
      this.edit_hospital_in_group = JSON.parse(JSON.stringify(hos));
      this.edit_hospital_group_ind = hos_grp_ind;
      this.edit_hospital_ind = hos_ind;
    },
    add_new_hospital (hos_grp_ind) {
      let det = false;
      for (let i in this.hospital_group_list[hos_grp_ind].hospital_list) {
        if (this.hospital_group_list[hos_grp_ind].hospital_list[i].value === this.new_hospital.value) {
          det = true;
        }
      }
      if (!det) {
        this.hospital_group_list[hos_grp_ind].hospital_list.push(JSON.parse(JSON.stringify(this.new_hospital)));
      } else {
        alert('Hastane bu grupta eklenmiş.');
      }
    },
    AddHospitalGroup: function () {
      let det = false;
      for (let i in this.hospital_group_list) {
        if (this.hospital_group_list[i].value === this.hospital_group.value) {
          det = true;
        }
      }
      if (!det) {
        this.hospital_group_list.push(this.hospital_group);
      } else {
        alert('Bu grup tanımlı');
      }
    },
    EditHospitalGroup: function (hos_grp, hos_grp_ind) {
      this.edit_hospital_group = JSON.parse(JSON.stringify(hos_grp));
      this.edit_hospital_group_ind = hos_grp_ind;
    },
    SaveEditHospitalGroup: function () {
      this.hospital_group_list[this.edit_hospital_group_ind] = JSON.parse(JSON.stringify(this.edit_hospital_group));
      this.edit_hospital_group = '';
    },
    onFileChange (logo_ind, type, wera_support_ind = false) {
      // var files = e.target.files || e.dataTransfer.files;
      var files = document.getElementById(logo_ind).files;
      if (!files.length) {
        return;
      }
      this.createImage(files[0], logo_ind, type, wera_support_ind);
    },
    createImage (file, logo_ind, type, wera_support_ind) {
      var reader = new FileReader();
      var vm = this;
      if (type === 'logo') {
        reader.onload = (e) => {
          vm.hospital_data.logo[logo_ind] = e.target.result;
        };
      } else if (type === 'wera_support') {
        reader.onload = (e) => {
          vm.hospital_data.wisdomera_support[wera_support_ind].image = e.target.result;
        };
      }
      reader.readAsDataURL(file);
    },
    /*    onFileSelected (event) {
          for (let i in event.target.files) {
            this.selected_file = event.target.files[i];
          }
        }, */
    DeletePhone (dep_ind, ph_ind) {
      this.hospital_data.departments[dep_ind].phones.splice(ph_ind, 1);
    },
    DeleteDepartment (dep_ind) {
      this.hospital_data.departments.splice(dep_ind, 1);
    },
    add_department: function () {
      let det = false;
      for (let i in this.hospital_data.departments) {
        if (this.hospital_data.departments[i].name === this.department) {
          det = true;
          alert('Bu departman sistemde mevcuttur.');
          break;
        }
      }
      if (!det) {
        this.hospital_data.departments.push({ name: this.department, manager: this.department_manager, phones: [] });
      }
    },
    add_new_phone: function () {
      if (this.selected_dep_ind !== '' && this.hospital_data.departments[this.selected_dep_ind]) {
        let det = false;
        for (let i in this.hospital_data.departments[this.selected_dep_ind].phones) {
          if (this.hospital_data.departments[this.selected_dep_ind].phones[i].no === this.telephone) {
            det = true;
            alert('Bu no zaten kayıtlı');
          }
        }
        if (!det) {
          this.hospital_data.departments[this.selected_dep_ind].phones.push({ person: this.person, no: this.telephone });
        }
      }
    },
    save: function () {
      HospitalService.save(this.hospital_data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.hospital_data = resp.data.result;
            alert(this.$t('wdm16.12661'));
          } else {
            alert('Kayıt işlemi başarısız.');
          }
        });
    }
  },
  watch: {
    'd_selectedBranchTab': function () {
      if (this.d_selectedBranchTab.value) {
        let query = 'hospitalGroup=' + this.hospital_data.hospital_group;
        query += '&';
        query += 'hospitalCode=' + this.hospital_data.hospital_id;
        query += '&';
        query += 'branch_value=' + this.d_selectedBranchTab.value;
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Branşa ait personeller getiriliyor. Lütfen bekleyiniz.' } });
        HospitalService.get_personnel_list_by_this_matched_branch(query)
          .then(resp => {
            this.d_branchMatchedPersonnels = resp.data.result.list;
            this.$store.commit('Loading', { 'status': false, 'data': {} });
          });
      }
    },
    'selected_nav_tab': function () {
      if (this.selected_nav_tab === 'UnitDefinitions') {
        let query = 'HospitalGroup=' + this.hospital_data.hospital_group + '&' + 'hospitalCode=' + this.hospital_data.hospital_id;
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'UnitDefinitions bilgileri getiriliyor' } });
        console.log(query);
        WebService.get_unit_definitions_of_hospital(query)
          .then(resp => {
            this.d_himsUnitDefinitions = resp.data.result;
            this.$store.commit('Loading', { 'status': false, 'data': {} });
          });
      } else if (this.selected_nav_tab === 'TransferPatientDataPersonnelIdList') {
        let query = 'hospitalGroup=' + this.hospital_data.hospital_group + '&' + 'hospitalCode=' + this.hospital_data.hospital_id;
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Hastane personel ile eşleştirilmiş branş listesi getiriliyor.' } });
        HospitalService.get_matched_branch_list(query)
          .then(resp => {
            this.d_hospitalMatchedBranchIdList = resp.data.result.list;
            this.$store.commit('Loading', { 'status': false, 'data': {} });
          });
      } else if (this.selected_nav_tab === 'TransferOperations') {
        //
        this.f_getWdmr224List();
      }
    }
  }
};

</script>
<style type="text/css">
.wtl-full-screen {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: solid 1px #20a8d8;
  /*background-color: rgba(0, 0, 0, .5);*/
  display: table;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  margin-bottom: 0px;
}

.not-wtl-full-screen {
  width: 100%;
  height: 100%;
  border: solid 1px #20a8d8;
  /*background-color: rgba(0, 0, 0, .5);*/
  display: table;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  margin-bottom: 0px;
}

.model-selected {
  background-color: #8ee08e;
  margin-left: 20px;
  cursor: pointer;
  border-radius: 3px;
}

.model-not-selected {
  margin-left: 20px;
  margin: 0px;
  cursor: pointer;
}

</style>
