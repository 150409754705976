<template>
  <div>
    <template v-if="hospital_data.hospital_id">
      <hospital-data :hospital_data="hospital_data" :hospital_wdm_list="hospital_wdm_list" :hospital_wtl_list="hospital_wtl_list"></hospital-data>
    </template>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex';
// import {default as router} from '@/router';
import HospitalService from '@/services/hospital';
import HospitalData from '@/components/widgets/HospitalData';
import OptionService from '@/services/option';

export default {
  name: 'hospital',
  computed: {},
  data () {
    return {
      hospital_wdm_list: {},
      hospital_wtl_list: [],
      selected_nav_tab: 'hospital_code',
      user: {},
      selected_group_ind: '',
      selected_hospital_ind: '',
      hospital_group_data: {},
      edit_hospital_in_group: { 'caption': '', 'label': '' },
      edit_hospital_ind: '',
      hospital_group_list: [],
      hospital_group: { 'caption': '', 'label': '', 'hospital_list': [] },
      new_hospital: { 'caption': '', 'label': '' },
      edit_hospital_group: { 'caption': '', 'label': '' },
      edit_hospital_group_ind: '',
      logo_list: ['logo_1', 'logo_2', 'logo_3', 'logo_4', 'logo_5'],
      selected_image: '',
      selected_file: '',
      department_manager: '',
      selected_dep_ind: '',
      department: '',
      person: '',
      telephone: '',
      hospital_data: {
        'headers': {
          'hospital_name': {
            'translation': { 'tr': '', 'en': '' }
          },
          'recipe_drugs_module': {
            'translation': { 'tr': '', 'en': '' }
          },
          'chemotherapy_protocol_scheme': {
            'translation': { 'tr': '', 'en': '' }
          }
        },
        'address': '',
        'departments': [],
        'logo': { 'logo_1': '', 'logo_2': '', 'logo_3': '', 'logo_4': '', 'logo_5': '' },
        'hospital_id': '',
        'id': '',
        'center_phone_number': ''
      },
      dataModels: [],
      option_data: {},
      selected_data_type: ''
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.f_getHospital();
  },
  methods: {
    f_getHospital: function (hospital_ind) {
      this.hospital_wdm_list = {};
      this.d_showTrueFalseHospitalData = false;
      this.selected_hospital_ind = hospital_ind;
      let hospital_group = this.user.hospital_group;
      let hospital_id = this.user.hospital_id;
      let query = 'group=' + hospital_group + '&hospital=' + hospital_id;
      HospitalService.get(query)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.hospital_data = resp.data.result[0];
          } else {
            this.hospital_data = {
              'headers': {
                'hospital_name': { 'translation': { 'tr': '', 'en': '' } },
                'recipe_drugs_module': { 'translation': { 'tr': '', 'en': '' } },
                'chemotherapy_protocol_scheme': { 'translation': { 'tr': '', 'en': '' } }
              },
              'address': '',
              'departments': [],
              'logo': { 'logo_1': '', 'logo_2': '', 'logo_3': '', 'logo_4': '', 'logo_5': '' },
              'hospital_id': hospital_id,
              'hospital_group': hospital_group,
              'id': '',
              'center_phone_number': ''
            };
            this.hospital_data.hospital_id = hospital_id;
            this.hospital_data.hospital_group = hospital_group;
          }
          let query = 'hospitalGroup=' + hospital_group + '&' + 'hospitalCode=' + hospital_id;
          OptionService.getHospitalWisdomDataModelList(query)
            .then(resp => {
              if (resp.data.status === 'success') {
                this.hospital_wdm_list = resp.data.result;
              } else {
                this.hospital_wdm_list = {};
              }
              OptionService.getHospitalWtl(query)
                .then(resp => {
                  if (resp.data.status === 'success') {
                    this.hospital_wtl_list = resp.data.result;
                  } else {
                    this.hospital_wtl_list = [];
                  }
                  this.d_showTrueFalseHospitalData = true;
                });
            });
        });
    }
  },
  components: {
    HospitalData
  }
};

</script>

